import consumer from "./consumer"

$(document).ready(function() {
  var room_ids = []
  var result=document.querySelectorAll('[data-room-id]');
  for (var index in result){
    if (result.hasOwnProperty(index)){
      room_ids.push(result[index].getAttribute('data-room-id'))
    }
  }
  $.each(room_ids, function(index, room_id) {

    consumer.subscriptions.create({channel: "RoomChannel", room_id: room_id}, {
      connected() {
        console.log("connected...");
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        $(".message-type-box").append('<div class="message">'+data.user_name+ ' : ' + data.message + '</div>')
        $('.message-type-box').animate({scrollTop: $('.message-type-box').prop("scrollHeight")}, 500);
        $('#message_content').val('')
      }
    });
  });

  // Hide notifications after 2 seconds
  $(".alert-holder").fadeTo(2000, 500).slideUp(500, function() {
    $(".alert-holder").slideUp(500);
  });

  $(document).click(function(event) {
    if(!$(event.target).closest('.navbar-collapse').length && !$(event.target).is('.navbar-toggler')) {
      if($('.navbar-collapse').hasClass('show')) {
          $('.navbar-collapse').removeClass('show');
      }
    }           
  });

  $('.default-profile').click(function () {
    $('.navbar-collapse').removeClass('show');
  });
});

$(document).on('turbolinks:load', function() {
  const dataTableInfo = $('.dataTables_info');
  if (dataTableInfo) {
    dataTableInfo.remove();
  }
  // Per page item selection for sender active orders dashboard.
  $('#senderDashboardItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/dashboard",
        data: { pagy_items_count: selectedValue }   
      });
    }
  });

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  $('#travelersDashboardItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/travelers/orders",
        data: { pagy_items_count: selectedValue, status: statusParam}
      });
    }else{
      if (selectedValue){
        $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/travelers/orders",
        data: { pagy_items_count: selectedValue}
      });
      }
    }
  });
  
  $('#publicTripItemsCount').change(function() {
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/public/trips",
        data: { pagy_items_count: selectedValue }   
      });
    }
  });

  $("#FeedbackItemsCount").change(function () {
    // Get the selected value
    var selectedValue = $(this).val();
    var userRole = $(this).data('name')
    if (selectedValue && userRole) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: "script",
        url: `/users/feedbacks/${userRole}`,
        data: { pagy_items_count: selectedValue },
      });
    }
  });
  
  // Per page item selection for sender company trips dashboard.
  $('#senderDashboardCompanyItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/dashboard",
        data: { pagy_company_items_count: selectedValue, status: statusParam }   
      });
    }else{
      if(selectedValue) {
        $.ajax({
          type: "GET",
          contentType: "application/json",
          dataType: 'script',  
          url: "/senders/dashboard",
          data: { pagy_company_items_count: selectedValue }   
        });
      }

    }
  });

  // Per page item selection for sender order.
  $('#senderOrdersItemsCount').change(function() {
    console.log('hii');
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/orders",
        data: { pagy_items_count: selectedValue, status: statusParam }   
      });
    }else{
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/orders",
        data: { pagy_items_count: selectedValue}   
      });
    }

    }
  });

  $('#forwarderDashboardItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/forwaders/orders",
        data: { pagy_items_count: selectedValue, status: statusParam}
      });
    }else{
      if (selectedValue){
        $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/forwaders/orders",
        data: { pagy_items_count: selectedValue}
      });
      }
    }
  });

  // feedback rating
  $(".rating input:radio").attr("checked", false);

  $(".rating input").click(function () {
    $(".rating span").removeClass("checked");
    $(this).parent().addClass("checked");
  });

  $("input:radio").change(function () {
    var userRating = this.value;
    $("#feedback_rating").val(userRating);
  }); 
});